import React, { useState } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import ReactHtmlParser from 'react-html-parser';

export default function AllCoverages () {
    const[pages, getPages] = useState(null)
    return (
        <StaticQuery
            query = {graphql`
            {
                coverage_pages: allButterPage(filter: {page_type: {eq: "product_page_01_20_2022"}}, sort: {order: ASC, fields: h1}) {
                    nodes {
                        slug
                        industry_page_hero {
                            fields {
                                thumbnail
                                Description
                                hero_headline
                                title
                                hero_image
                                hero_paragraph
                                image
                            }
                        }
                    }
                }
            }
        `}
            render = {data => (
                <div id="all_coverages">
                    {getPages(data.coverage_pages.nodes)}
                    {data.coverage_pages.nodes && data.coverage_pages.nodes.map((page, index) => (
                        <div className="page-listing">
                            <ul id="pages" key={index}>
                                <Link to={"/"+page.slug}>
                                    <li className="page">
                                        <div className="listing-thumbnail">
                                            <div className="halo">
                                                <div className="halo">
                                                    <div className="bullseye"><img src={page.thumbnail} alt={`${page.slug} icon`} /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <h4>{ReactHtmlParser(page.h1)}</h4>
                                        <p className="line-clamp">{ReactHtmlParser(page.title)}</p>
                                    </li>
                                </Link>
                            </ul>
                        </div>
                    ))}
                </div>
            )}
        />
    )
}





