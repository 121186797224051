import React from 'react';
import Header from '../components/Header'
import '../styles/all-coverages.css'
import Footer from '../components/Footer';
import AllCoverages from '../components/AllCoverages';
import TopNav from '../components/business-coverage/TopNav';
import { Helmet } from "react-helmet"

const AllCoverageTypes = () => {

    return(
        <div id="all_coverage_types">
            <Helmet><title>Types of Business Insurance: Insurance321</title><meta name="description" content="Browse types of business insurance coverage along with recommendations by industry"></meta></Helmet>
            <TopNav />
            <Header />
            <div className="listing-container">
                <h2>Business Insurance Coverage Types</h2>
                <AllCoverages />
            </div>
            
            <Footer />
        </div>
    )
}

 
export default AllCoverageTypes;